/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ctrl/ngx-emoji-mart/picker.css";

html {
  background: #fff;
  height: 100%;
}

body {
  height: 100%;
  min-width: 1050px;
  background: #E6E6E6;
  color: #2c2d30;
  padding: 0;
  margin: 0;
  //overflow-y: hidden;
  //overflow-x: hidden;
  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //user-select: none;
}

* {
  font-family: "Roboto", sans-serif;
  scrollbar-width: thin;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  font-family: 'Nunito', sans-serif;
}

b, strong {
  font-weight: bold;
}

h6 {
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  color: #000000;
  margin: 0;
}

.float-left {float: left; margin-left: 0;}
.float-right {float: right; margin-right: 0;}
.clear-fix:after {content: ""; display: block; clear: both; float: none;}

app-root {
  //display: block;
  //width: 100%;
  //height: 100%;
}

//custom input fields

.tm-input-ext {
  position: relative;
  height: 48px;
  width: 100%;
  margin: 0;
  text-align: left;
  overflow: hidden;
}

.tm-input-ext:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #7BB4F4;
}

.tm-input-placeholder,
.tm-input-ext input {
  position: absolute;
  height: 24px;
  left: 0;
  right: 0;
  top: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.35);
  z-index: 1;
  transition: all .3s cubic-bezier(0.4,0,0.2,1);
}

.tm-input-ext input {
  top: 22px;
  color: #000000;
  border: 0;
  margin: 0;
  padding: 0;
  z-index: 2;
  background: transparent;
  outline: none !important;
  width: 100%;
  font-weight: bold;
}

.tm-input-ext input:focus + .tm-input-placeholder,
.ng-dirty + .tm-input-placeholder,
.ng-valid + .tm-input-placeholder {
  height: 16px;
  left: 0;
  right: 0;
  top: 5px;
  line-height: 16px;
  font-size: 12px;
}

.tm-input-item-new {
  height: 32px;
  position: relative;
}

.tm-input-item-new input {
  text-align: left;
  border: 0;
  border-bottom: 2px solid #1D7EED;
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  padding: 0;
  outline: none;
  padding-right: 25px;
}

.tm-input-item-new.text-center input {
  text-align: center;
}

.tm-input-item-new .ng-invalid {
  border-bottom: 2px solid #EE5253;
}

.tm-input-item-new .btn-ok {
  border: 0;
  background: url(assets/img/ico-okay-blue.svg) no-repeat center center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
  outline: none;
}

//buttons

.tm-btn {
  height: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  border-radius: 8px;
  background: #1D7EED;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  border: 0;
  padding: 0 12px;
}

.tm-btn-grey {
  background: #F4F4F4;
  color: #1D7EED;
}

.tm-btn-red {
  background: #EE5253;
  color: #fff;
}

.tm-btn:hover {
  background: #116BD4;
}

.tm-btn-red:hover {
  background: #D4494A;
}

.tm-btn-grey:hover {
  background: #F0F0F0;
  color: #116BD4;
}

.tm-btn-transp {
  background: transparent;
  color: #000;
}

.tm-btn-transp:hover {
  background: transparent;
  color: #000;
}

.tm-btn[disabled] {
  background: #C6DFFA;
  cursor: not-allowed;
}

.tm-modal-buttons-holder .tm-btn {
  padding: 0 16px;
}

.tm-pin-button {
  position: absolute;
  width: 10px;
  height: 10px;
  background: url(assets/img/ico-pin.svg) no-repeat center center;
}

.tm-string-shortener {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//scrollbar for webkit
.styled-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #aaa #eee;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

//thick scroll for chat window
.tm-chat-body::-webkit-scrollbar {
  width: 10px;
}

.tm-chat-body::-webkit-scrollbar-track,
.tm-chat-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

//narrow scroll for teams
.new-message-text-holder::-webkit-scrollbar,
.mention-suggestions::-webkit-scrollbar,
.tm-groups::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar {
  height: 8px;
}

.new-message-text-holder::-webkit-scrollbar-track,
.new-message-text-holder::-webkit-scrollbar-thumb,
.mention-suggestions::-webkit-scrollbar-track,
.mention-suggestions::-webkit-scrollbar-thumb,
.tm-groups::-webkit-scrollbar-track,
.tm-groups::-webkit-scrollbar-thumb {
  border-radius: 2px;
}

.styled-scrollbar:hover::-webkit-scrollbar-track {
  background-color: #eee;
  opacity: 0.9;
}

.new-message-text-holder::-webkit-scrollbar-track-piece,
.mention-suggestions::-webkit-scrollbar-track-piece {
  background: transparent;
}

body::-webkit-scrollbar-thumb,
.styled-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
}

body::-webkit-scrollbar-thumb:hover,
.styled-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8f8f8f;
}

@media all and (max-width: 1026px) {
  ::-webkit-scrollbar {
    //width:.15625rem
  }
}

@media all and (max-width: 642px) {
  ::-webkit-scrollbar {
    width:auto
  }
}

//checkboxes - switchers

.tm-checkbox {
  opacity: 0;
  z-index: 2;
  position: absolute;
  right: 16px;
  width: 34px;
  height: 16px;
  margin: 0;
  top: calc(50% - 8px);
}

.tm-switcher {
  position: absolute;
  width: 34px;
  height: 16px;
  right: 16px;
  top: calc(50% - 8px);
  background: #A1C9F7;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.35;
  transition: all 0.3s 0s;
}

.tm-switcher:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 2px;
  top: calc(50% - 6px);
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s 0s;
}

.tm-checkbox:hover + .tm-switcher {
  background: #7BB4F4;
}

.tm-checkbox:checked + .tm-switcher {
  opacity: 1;
  background-color: #4293F0;
}

.tm-checkbox:checked:hover + .tm-switcher {
  background: #1D7EED;
}

.tm-checkbox:checked + .tm-switcher:before {
  left: auto;
  right: 2px;
  transition: all 0.3s 0s;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tm-chat-subtitle {
  font-weight: bold;
  margin: 20px 0 10px;
  line-height: 12px;
  font-size: 13px;
  color: #B3B3B3;
}

/* MODAL STYLES
-------------------------------*/

/*
Dialog styles take from: http://jasonwatmore.com/post/2018/05/25/angular-6-custom-modal-window-dialog-box
https://github.com/cornflourblue/angular-6-custom-modal
 */


jw-modal {
  display: none;

  .jw-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    height: 100%;

    .jw-modal-body {
      @include centerer;
      //height: 90%;
    }

    .tm-modal-header {
      border-radius: 8px 8px 0 0;
      background: #F4F4F4;
      text-align: left;
      min-height: 48px;
      padding: 14px 16px 12px;
    }

    .modal-with-close {
      text-align: left;
      position: relative;
      height: 56px;
      padding: 16px 16px 14px;
      box-shadow: inset 0px -1px 0.5px #EBEBEB;
    }

    .tm-close-modal {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 16px;
      top: 14px;
      background: rgba(0,0,0,0.05);
      border-radius: 8px;
      cursor: pointer;
      z-index: 3;
    }

    .tm-close-modal:hover {
      background: rgba(0,0,0,0.1);
    }

    .tm-close-modal:before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      left: 3px;
      top: 3px;
      background: url(assets/img/ico-close-black.svg) no-repeat center center;
    }

    .tm-modal-body {
      border-radius: 0 0 8px 8px;
      background: #fff;
      padding: 16px;
      position: relative;
      max-height: 80vh;
    }

    .confirm-popup p {
      font-size: 14px;
      color: #000000;
    }

    .tm-modal-wrapper {
      padding-bottom: 47px;
    }

    .tm-modal-buttons-holder {
      padding-top: 11px;
    }
  }

  .jw-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 900;
  }
}

body.jw-modal-open {
  overflow: hidden;
}

#imageview-modal .jw-modal-body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#imageview-modal .jw-modal-background {
  background: rgba(0,0,0,0.7);
}

#confirm-delete .tm-modal-wrapper {
  padding-bottom: 0;
}

#newteam-modal,
#confirm-delete {
  position: absolute;
  z-index: 1001;
}

//emoji block

.emoji-block {
  position: absolute;
  bottom: 40px;
  left: 50px;
  z-index: 6;
  display: none;

  //z-index: 6;
  //left: auto;
  //right: calc(100% + 20px);
  //bottom: -5px;
}

.showEmoji .emoji-block {
  display: block;
}

.emoji-mart-emoji-native span {
  font-size: 18px !important;
  line-height: normal;
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji" !important;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}



.tm-modal-holder {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  //background: rgba(15, 166, 127, 0.07);
}

[data-show-modal-menu] .tm-modal-holder {
  display: block;
}

.mat-autocomplete-panel {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35) !important;
  border-radius: 8px;
  margin-top: 4px;
  margin-left: -41px;
  min-width: 400px !important;
  max-width: 400px !important;
  max-height: 480px !important;
  overflow: hidden !important;
}

.chatLink {
  color: #1a0dab !important;
  text-decoration: underline;
  word-break: break-all;
}

.tm-own-message .chatLink {
  color: #fff !important;
}

.tm-dropdown {
  position: absolute;
  background: #fff;
  z-index: 10;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  width: 180px;
}

.backdrop-chatslist-context + .cdk-overlay-connected-position-bounding-box .tm-dropdown {

}

.backdrop-chat-context + .cdk-overlay-connected-position-bounding-box .tm-dropdown {
  margin-left: -180px;
  margin-top: 10px;
  transform-origin: right bottom !important;
}

[data-show-up="true"] + .cdk-overlay-connected-position-bounding-box .tm-dropdown {
  bottom: 100% !important;
  //margin-top: 18px;
  margin-bottom: 10px;
  transform-origin: right top !important;
}

.tm-dropdown.sort-list {
  right: 16px;
  top: 33px;
}

.tm-dropdown .dropdown-item {
  padding: 13px 16px 12px 56px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  min-width: 160px;
  z-index: 6;
  line-height: normal;
}

.tm-dropdown .dropdown-item:hover {
  background-color: #f6f6f6;
}

.tm-dropdown .dropdown-item:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 50%;
  margin-top: -12px;
  background: no-repeat center center;
}

.tm-dropdown .ico-checkbox:before {background-image: url(assets/img/ico-checkbox.svg); opacity: 0.4;}
.tm-dropdown .ico-checkbox.checked:before {background-image: url(assets/img/ico-checkbox-checked.svg);}
.tm-dropdown .ico-copy:before {background-image: url(assets/img/ico-copy.svg);}
.tm-dropdown .ico-copy-link:before {background-image: url(assets/img/ico-link.svg);}
.tm-dropdown .ico-edit:before {background-image: url(assets/img/ico-edit-black.svg);}
.tm-dropdown .ico-delete:before {background-image: url(assets/img/ico-delete.svg);}
.tm-dropdown .ico-forward:before {background-image: url(assets/img/ico-forward.svg);}
.tm-dropdown .ico-mute:before {background-image: url(assets/img/ico-unmute.svg);}
.tm-dropdown .ico-pin:before {background-image: url(assets/img/ico-pin-2.svg);}
.tm-dropdown .ico-archive:before {background-image: url(assets/img/ico-archive.svg);}
.tm-dropdown .ico-download:before {background-image: url(assets/img/ico-download-file-gray.svg);}
.tm-dropdown .ico-open:before {}

.tm-dropdown .dropdown-item:hover:before {
  opacity: 1;
}

//.emoji-mart-emoji-native span {
//  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", sans-serif;
//}

.ico-lock {
  display: inline-block;
  width: 11px;
  height: 13px;
  background: url(/assets/img/ico-lock.svg) no-repeat center center;
  margin: 0 4px -1px 0;
}

.tm-chat-loading {
  position: absolute;
  left: 430px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 100;
}

.hide-chat-loading {
  display: none;
}

//.tm-loader-image {
//  position: absolute;
//  width: 300px;
//  height: 200px;
//  margin: auto;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//}

.tm-loading-wrapper,
.tm-loading-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.tm-is-home .tm-chat-loading {
  background: #F4F4F4;
  border-left: 1px solid rgba(0,0,0,0.1);
}

.tm-is-home .tm-loading-wrapper {
  left: 16px;
  right: 16px;
  top: 16px;
  height: 328px;
  box-shadow: 0 0 6px rgba(51, 51, 51, 0.3);
  border-radius: 8px;
  background: #fff;
}

.tm-loading-header {
  height: 72px;
  position: relative;
}

.tm-loading-ava-1,
.tm-loading-ava-2 {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  top: 16px;
  border-radius: 50%;
  background: #000000;
  opacity: 0.05;
}

.tm-loading-header:before,
.tm-loading-header:after {
  content: "";
  position: absolute;
  width: 88px;
  height: 12px;
  left: 16px;
  top: 20px;
  background: #000000;
  opacity: 0.05;
  border-radius: 4px;
}

.tm-loading-header:after {
  width: 28px;
  height: 12px;
  left: 16px;
  top: 40px;
}

.tm-loading-body {
  top: 72px;
  left: 0;
  bottom: 88px;
}

.tm-loading-body:before {
  content: "";
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  height: 1px;
  background: #000000;
  opacity: 0.1;
}

.tm-loading-ava-2 {
  top: 16px;
  right: auto;
  left: 16px;
}

.tm-loading-bubble-1,
.tm-loading-bubble-2,
.tm-loading-bubble-3,
.tm-loading-bubble-4 {
  position: absolute;
  border-radius: 20px;
  background: #000000;
  opacity: 0.05;
  height: 34px;
  width: 300px;
}

.tm-is-home .tm-loading-bubble-3,
.tm-is-home .tm-loading-bubble-4 {
  display: none;
}

.tm-loading-bubble-1 {
  left: 60px;
  top: 34px;
}

.tm-loading-bubble-2 {
  width: 150px;
  left: 60px;
  top: 74px;
}

.tm-loading-bubble-3 {
  right: 16px;
  top: 134px;
}

.tm-loading-bubble-4 {
  width: 150px;
  right: 16px;
  top: 174px;
}

.tm-loading-footer {
  position: absolute;
  height: 48px;
  left: 16px;
  right: 16px;
  bottom: 20px;
  background: #fff;
  border: 2px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.tm-loading-sq-button-1,
.tm-loading-sq-button-2 {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: calc(50% - 24px/2);
  background: #000000;
  opacity: 0.05;
  border-radius: 4px;
}

.tm-loading-sq-button-2 {
  left: 48px;
}

.tm-loading-msg-line {
  position: absolute;
  width: 220px;
  height: 12px;
  left: 92px;
  top: calc(50% - 12px/2);
  background: #000000;
  opacity: 0.05;
  border-radius: 4px;
}

.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.refresh-cache-1 {}


//fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic-ext,latin-ext');

//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 100;
//  src: local('Roboto'), local('Roboto-Thin'), url(/assets/fonts/Roboto-Thin.woff) format('woff');
//}
//
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Roboto'), local('Roboto-Light'), url(/assets/fonts/Roboto-Light.woff) format('woff');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Roboto'), local('Roboto-Regular'), url(/assets/fonts/Roboto-Regular.woff) format('woff');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 500;
//  src: local('Roboto Medium'), local('Roboto-Medium'), url(/assets/fonts/Roboto-Medium.woff) format('woff');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Roboto Bold'), local('Roboto-Bold'), url(/assets/fonts/Roboto-Bold.woff) format('woff');
//}
